import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import FlairText from './FlairText'
import PagePadding from './PagePadding'
import RichText from './RichText'
import SerifText from './SerifText'

function BlogPosts() {
  const data = useStaticQuery(graphql`
    query blogPostsQuery {
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        nodes {
          id
          title
          publishedAt
          slug {
            current
          }
          _rawExcerpt
          mainImage {
            alt
            asset {
              gatsbyImageData(
                width: 768
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
      }
      file(relativePath: { eq: "placecards-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 768
            quality: 60
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
    }
  `)
  const posts = data.allSanityPost.nodes
  return (
    // TODO: Add pagination
    <List as="ul">
      {posts.map(post => {
        const {
          id,
          title,
          publishedAt,
          slug: { current: postSlug },
          _rawExcerpt,
          mainImage,
        } = post
        const thumbnailSrc =
          mainImage?.asset?.gatsbyImageData ||
          data?.file?.childImageSharp?.gatsbyImageData
        const thumbnailAlt = mainImage
          ? mainImage.alt
          : 'Hand-crafted acrylic placecards'
        return (
          <Post key={id}>
            <ImageLink to={postSlug}>
              <Thumbnail image={thumbnailSrc} alt={thumbnailAlt || ''} />
            </ImageLink>
            <Text>
              <Link to={postSlug}>
                <Title as="h2" dangerouslySetInnerHTML={{ __html: title }} />
              </Link>
              <DatePosted>{format(publishedAt, 'MMMM DD, YYYY')}</DatePosted>
              <RichText
                value={_rawExcerpt}
                components={{
                  block: {
                    normal: ExcerptParagraph,
                  },
                }}
              />
              <MoreLink as={Link} to={postSlug}>
                Read more
                <Arrow icon={faArrowAltCircleRight} />
              </MoreLink>
            </Text>
          </Post>
        )
      })}
    </List>
  )
}

const List = styled(PagePadding)`
  @media (min-width: 768px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`

const Post = styled.li`
  margin: 0 -1rem;
  padding: 0 2rem 2rem;
  display: flex;
  flex-flow: column nowrap;
  &:nth-child(2n) {
    background: ${({ theme }) => theme.colors.secondary};
  }
  @media (min-width: 768px) {
    flex-flow: row wrap;
    margin: 2rem 0;
    padding-top: 2rem;
  }
`

const Title = styled(SerifText)`
  color: ${props => props.theme.colors.highlight};
  font-size: 1.5rem;
  margin: 1rem 0;
  &:hover {
    text-decoration: underline;
  }
  @media (min-width: 768px) {
    font-size: 2.5rem;
    margin-top: 0;
  }
`

const DatePosted = styled(FlairText)`
  margin: 0;
  font-size: 0.75rem;
`

const ExcerptParagraph = styled.p`
  font-size: 0.875rem;
  line-height: 1.8285714286em;
  margin: 1.5rem 0;
`

const ImageLink = styled(Link)`
  margin-left: -32px;
  margin-right: -32px;

  @media (min-width: 768px) {
    width: 38%;
    margin-left: 0;
    margin-right: 2rem;
  }
`

const Thumbnail = styled(GatsbyImage)<{ image: any }>`
  width: 100%;
  height: 250px;

  @media (min-width: 768px) {
    height: 315px;
  }
`

const Text = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
`

const MoreLink = styled(FlairText)`
  color: ${props => props.theme.colors.highlight};
  font-size: 0.75rem;
  margin-top: auto;
  &:hover {
    text-decoration: underline;
  }
`

const Arrow = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`

export default BlogPosts
