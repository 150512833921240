import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BlogPosts from '../components/BlogPosts'
import { graphql } from 'gatsby'
import PageData from '../types/PageData'

type Props = {
  data: {
    sanityPage: PageData
  }
}

const BlogPage: React.FC<Props> = ({ data }) => {
  return (
    <Layout
      pageData={data?.sanityPage}
      pageTitle={{
        mobile: 'Blog',
        desktop: <>Blog &nbsp;|&nbsp; Cody Calligraphy</>,
      }}
    >
      <Seo
        title="Blog"
        description="Read my blog for DIY wedding ideas, wedding planning tips, and brush calligraphy tutorials. Keep up with my events & learn calligraphy with me!"
      />
      <BlogPosts />
    </Layout>
  )
}

export const query = graphql`
  query BlogPageQuery {
    sanityPage(uuid: { eq: "blog" }) {
      _rawPageMessage
    }
  }
`

export default BlogPage
